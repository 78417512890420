<template>
  <v-container>
    <v-row id="form-padding">
      <v-col cols="12">
        <div class="display-1 text-center">Vytvoření poptávky</div>
      </v-col>
      <DemandPropertyCardMiniature
        :demand="newDemand" :property-id="newDemand.property.id" :room-id="newDemand.room.id"/>
      <v-col lg="6" md="12" sm="12" xs="12">
        <DemandForm
          :demand="newDemand"
          @fetched-demand-price="updatePropertyCardPrice"
        ></DemandForm>
        <v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DemandForm from '../../components/demands/DemandForm'
import { mapGetters } from 'vuex'
import DemandPropertyCardMiniature from '../../components/property/DemadPropertyCardMiniature'

export default {
  name: 'CreateDemand',
  components: {
    DemandPropertyCardMiniature,
    DemandForm
  },
  data () {
    return {
      newDemand: {
        note: '',
        firm: false
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user'
    })
  },
  methods: {
    /**
     * Checks route query parameters
     * Rules:
     *     - dateFrom: required, valid date
     *     - dateTo: required, valid date, greater than dateFrom
     *     - property: required
     *     - room: nullable
     *     - adults: required, number
     *     - children: required, number
     *     - adults + children: greater than 0
     * @return boolean
     */
    validateQuery () {
      // /user/create-demand?property=2&room=null&dateFrom=2021-09-03&dateTo=2021-09-21&adults=1&children=1
      const query = this.$route.query
      if (!query.property) {
        return false
      }
      if (query.adults && +query.adults < 0) {
        return false
      }
      if (query.children === undefined || (query.children && +query.children < 0)) {
        return false
      }
      if (query.adults === undefined || (+query.adults + +query.children <= 0)) {
        return false
      }
      const dateFrom = new Date(query.dateFrom)
      const dateTo = new Date(query.dateTo)
      if (isNaN(dateFrom.getTime()) || isNaN(dateTo.getTime())) {
        return false
      }
      if (dateFrom.getTime() > dateTo.getTime()) {
        return false
      }
      return true
    },
    updatePropertyCardPrice (price) {
      this.$set(this.newDemand, 'price', price)
    }
  },
  created () {
    if (!this.validateQuery()) {
      this.$router.push({ name: 'homepage' })
    }
    const query = this.$route.query

    this.newDemand.dateFrom = query.dateFrom
    this.newDemand.dateTo = query.dateTo
    this.newDemand.user = this.user
    this.newDemand.adults = +query.adults
    this.newDemand.children = +query.children
    this.newDemand.property = {
      id: query.property
    }
    this.newDemand.room = {
      id: query.room || null
    }
  }
}
</script>

<style scoped>
#form-padding {
}

>>> .border-left-none fieldset {
  border-left: none !important;
}

>>> .border-right-none fieldset {
  border-right: none !important;
}

.avail-subtitle {
  font-size: 22px;
  color: #888888
}
</style>
